ngx-intl-tel-input .form-control {
  width: 104%;
  height: calc(1.5em + 0.75rem + 7.5px);
  padding: 0.375rem 3rem;

}
.iti * {
  color: #212529;
}
ngx-intl-tel-input .iti__country-list {
  max-height: 200px;
  max-width: 260px;
}
